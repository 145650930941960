import React from 'react';

const ProductRange = () => {
	return (
		<div className='product-range-wrapper'>
			<div className='product-range-title'>
				<h1>Product </h1>
				<h1> Range</h1>
			</div>
			<div className='product-range flex-container'>
				<div className='flex-container product-range-top'>
					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='#1e2530'
								stroke='#51a6c0'
								strokeWidth='6'
							/>
						</svg>
						<div className='product-range-info'>
							<h3>Types</h3>
							<p>Seamless, Continuous Weld, ERW, SAW, LSAW, HFW, Tubing</p>
						</div>
					</div>

					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='#1e2530'
								stroke='#51a6c0'
								strokeWidth='6'
							/>
						</svg>
						<div className='product-range-info product-range-info-large'>
							<h3>Sizes</h3>
							<p> Varied sizes, dimensions, lengths, and specifications</p>
							<h3>Materials of Construction</h3>
							<p>
								{' '}
								Plain Carbon Steels, low temperature grades, high yield grades
							</p>
							<h3>Standards</h3>
							<p>API, ASTM, ASME, ISO,</p>
							<p> DIN, EN</p>
							{/* <h3>Types</h3>
								<p>Seamless, Continuous Weld, ERW, SAW, LSAW, HFW, Tubing</p> */}
						</div>
					</div>
				</div>
				<div className='product-range-middle flex-container'>
					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='#1e2530'
								stroke='#51a6c0'
								strokeWidth='6'
							/>
						</svg>
						<div className='product-range-info product-range-info-large'>
							<h3>Carbon Steel Tubing and Casing Pipes</h3>
							<p>For onshore and offshore Oil and Gas applications</p>
						</div>
					</div>
					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='none'
								stroke='none'
								strokeWidth='6'
							/>
						</svg>
					</div>
					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='#1e2530'
								stroke='#51a6c0'
								strokeWidth='6'
							/>
						</svg>
						<div className='product-range-info product-range-info-large'>
							<h3>High Temperature Heat Exchangers and Boiler Tubes</h3>
							<p> Duplex Stainless Steel, Exotic Alloys</p>
						</div>
					</div>
				</div>
				<div className='flex-container product-range-bottom'>
					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='#1e2530'
								stroke='#51a6c0'
								strokeWidth='6'
							/>
						</svg>
						<div className='product-range-info product-range-info-large'>
							<h3>Tailored Solutions</h3>
							<p>Customized products to meet specific client requirements</p>
						</div>
					</div>

					<div className='product-range-card'>
						<svg
							width='282'
							height='312'
							viewBox='0 0 282 312'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M209.935 36.6243L209.92 36.6154L209.904 36.6066L159.245 7.89271C147.927 1.47802 134.073 1.47802 122.755 7.89271L72.0957 36.6066L72.075 36.6183L72.0544 36.6304L21.8577 66.146C10.6437 72.7398 3.71638 84.7382 3.61297 97.7467L3.15009 155.976L3.1499 156L3.15009 156.024L3.61297 214.253C3.71638 227.262 10.6437 239.26 21.8577 245.854L72.0544 275.37L72.075 275.382L72.0957 275.393L122.755 304.107C134.073 310.522 147.927 310.522 159.245 304.107L209.904 275.393L209.925 275.382L209.946 275.37L260.142 245.854C271.356 239.26 278.284 227.262 278.387 214.253L278.85 156.024L278.85 156.012V156V98.0405C278.85 84.8739 271.853 72.6992 260.476 66.0709L209.935 36.6243Z'
								fill='#1e2530'
								stroke='#51a6c0'
								strokeWidth='6'
							/>
						</svg>
						<div className='product-range-info'>
							<h3>Delivery</h3>
							<p>Prompt delivery services</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductRange;
